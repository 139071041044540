<template>
  <div>
    <div ref="scrollBox">
      <Header :navList="navList" :activeNav="activeNav" @handleClick="changeActive" />
      <!-- 内容区域 -->
      <div class="container">
        <div class="carimg" :class="{close:myTrue}"></div>
        <!-- 跳转和新闻区 -->
        <section class="skip_news" :class="{close:myTrue}">
          <!-- 跳转 -->
          <section class="skip">
            <section>
              <div class="info" style="background-color: rgba(0, 0, 0, 0.6)">
                <div style="width: 86%">
                  <h1>运营平台</h1>
                  <p>平台对所有托运人、承运人、司机、运单、发票等进行管理</p>
                </div>
                <div class="btn" @click="toPlatform">
                  <div><i style="font-size: 30px" class="el-icon-right"></i></div>
                  <el-link style="color: #D92E1E" type="primary">点击进入</el-link>
                </div>
              </div>
            </section>
            <section>
              <div class="info">
                <div style="width: 62%">
                  <h1>企业端</h1>
                  <p>
                    生产企业、贸易企业、物流公司等托运人发布货源、创建运单，同时对运单进行管理、运费结算，支持子账号模式
                  </p>
                </div>
                <div class="btn" @click="toEnterprise">
                  <div><i style="font-size: 30px" class="el-icon-right"></i></div>
                  <el-link style="color: #D92E1E" type="primary">点击进入</el-link>
                </div>
              </div>
            </section>
            <section>
              <div class="info" style="background-color: rgba(0, 0, 0, 0.6); padding-bottom: 60px">
                <div>
                  <h1>司机端</h1>
                  <p>通过APP接单、发货、在途信息管理、定位数据上传、收款管理</p>
                </div>
              </div>
              <div class="AppCode">
                <span>扫码下载</span>
                <img src="http://whptcs.dzwliu.com/static/ewm.png" alt="" />
              </div>
            </section>
          </section>
        </section>
        <div :class="{close:isTrue}">
          <!-- 关于我们 -->
          <section class="aboutUS">
            <img class="logo" src="../../../public/homeImg/_e-ABOUTUS.png" />
            <section class="content">
              <section class="title">
                <h1>关于我们</h1>
                <span>公司简介，相关资讯</span>
              </section>
              <ul class="imgList">
                <li>
                  <img src="../../../public/homeImg/index-3-pic02@2x.png" alt="" />
                </li>
                <li>
                  <img src="../../../public/homeImg/index-3-pic03@2x.png" alt="" />
                </li>
                <li>
                  <img src="../../../public/homeImg/index-3-pic04@2x.png" alt="" />
                </li>
                <li>
                  <img src="../../../public/homeImg/index-3-pic05@2x.png" alt="" />
                </li>
                <li>
                  <img src="../../../public/homeImg/index-3-pic06@2x.png" alt="" />
                </li>
              </ul>
              <section class="text">
                <h1>四川省交通运输集团有限责任公司</h1>
                <p>
                  四川省交通运输集团是四川省委省政府着力解决我省交通运输市场小、散、弱主体难题，促进我省物流业专业化、现代化国际化水平的重要举措之一。
                </p>
                <p style="margin-top: 3px;">
                  四川省交通运输集团以“交通四方，运达全球”为宗旨，立足四川、链接世界，现已成为全资质、全产业链的综合物流服务商—四川省交通运输行业龙头企业。
                </p>
              </section>
            </section>
            <img class="background" src="../../../public/homeImg/index-3-pic01@2x.png" />
          </section>
          <!-- 合作伙伴 -->
          <section class="partner">
            <section class="left">
              <p>
                根据实际情况，本次合作，甲乙双方合作方式为办事处和经销商的结合体——经销商式的办事处。这样，既能利用办事处的垂直性进行有效统筹管理，也可发挥经销商多点出击的战斗力。
              </p>
              <h1>合作机构：</h1>
              <h2>
                金龙客车、招商银行、富士康科技集团、腾讯、
                宇通客车、西南交通大学Lorem ipsum dolor sit amet consectetur
                adipisicing elit. Praesentium asperiores unde beatae quas quia, sit
                incidunt nisi dignissimos amet quo minima ducimus maxime, eos vero
                obcaecati corporis? Quibusdam, consectetur fugiat!
              </h2>
              <img src="../../../public/homeImg/index-4-pic02@2x.png" alt="" />
            </section>
            <section class="middle">
              <section class="title">
                <h1>合作伙伴</h1>
                <span>战略合作，多赢发展</span>
              </section>
              <section class="logos">
                <img src="../../../public/homeImg/index-comp-pic1.png" alt="" />
                <img src="../../../public/homeImg/index-comp-pic2.png" alt="" />
                <img src="../../../public/homeImg/index-comp-pic3.png" alt="" />
                <img src="../../../public/homeImg/index-comp-pic4.png" alt="" />
                <img src="../../../public/homeImg/index-comp-pic5.png" alt="" />
                <img src="../../../public/homeImg/index-comp-pic6.png" alt="" />
              </section>
            </section>
            <section class="right">
              <img src="../../../public/homeImg/index-4-pic01_1@2x.png" alt="" />
            </section>
          </section>
          <!-- 联系我们 -->
          <section class="contactUS">
            <section class="left">
              <img src="../../../public/homeImg/index-5-pic01@2x.png" alt="" />
            </section>
            <section class="right">
              <section class="title">
                <h1>联系我们</h1>
                <span>意见反馈，详情咨询</span>
              </section>
              <div>电话：180 8150 0858</div>
              <div>
                <div>地址：四川省达州市高新区木河路4号达州公路物流港综合办公楼2号楼3楼315、317号</div>
              </div>
            </section>
          </section>

        </div>
        <Footer />
      </div>
    </div>


  </div>

</template>

<script>
export default {
  data() {
    return {
      navList: [
        { name: "首页" },
        { name: "关于我们" },
        { name: "合作伙伴" },
        { name: "联系我们" },
      ],
      activeNav: "首页",
      isTrue: true,
      myTrue: false
    };
  },
  mounted() {
    this.initScroll();
  },
  beforeDestroy() {
    document.removeEventListener("scroll", this.listenerFunction);
  },
  methods: {
    //初始化滚动条监听
    initScroll() {
      document.addEventListener("scroll", this.handleScroll, true);
    },
    //监听滚动条移动
    handleScroll() {
      const pageY = window.pageYOffset;
      if (pageY >= 0 && pageY < 700 && this.activeNav != '首页') {
        this.activeNav = "关于我们";
      } else if (pageY >= 700 && pageY < 1100) {
        this.activeNav = "合作伙伴";
      } else if (pageY >= 1100 && pageY < 1700) {
        this.activeNav = "联系我们";
      } 
    },
    //修改当前激活nav
    changeActive(name, value) {
      this.activeNav = name;
      if(this.activeNav == '首页'){
        this.isTrue = true
        this.myTrue = false
      } else {
        this.isTrue = false
        this.myTrue = true
      }
        this.$nextTick(()=>{
          window.scrollTo(0, value);
        })
    },
    //跳转平台端
    toPlatform() {
      window.open("http://whptcs.dzwliu.com/platform/start/#/", "_blank");
    },
    //跳转企业端
    toEnterprise() {
      window.open("http://whhzcs.dzwliu.com/company/start/#/", "_blank");
    },
  },
};
</script>

<style lang="less" scoped>
.close{
  display: none;
}
.carimg{
  height: 500px;
  width: 100%;
  background: url("../../../public/homeImg/car.jpg") no-repeat
  center;
  background-size: 100% 100%;
}
.container {
  font-family: "Microsoft YaHei Regular";
  .skip_news {
    padding: 20px 150px;
    .skip {
      height: 400px;
      display: flex;
      justify-content: space-between;
      color: white;
      section:nth-child(1) {
        width: 27%;
        background: url("../../../public/homeImg/index-1-pic01@2x.png")
          no-repeat;
        background-size: 100% 100%;
      }
      section:nth-child(2) {
        width: 44%;
        background: url("../../../public/homeImg/index-1-pic02@2x.png")
          no-repeat;
        background-size: 100% 100%;
        display: flex;
        align-items: flex-end;
      }
      section:nth-child(3) {
        width: 27%;
        background: url("../../../public/homeImg/index-1-pic03@2x.png")
          no-repeat;
        background-size: 100% 100%;
      }
      .info {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.8);
        padding: 20px 23px;
        h1 {
          font-size: 20px;
          margin-bottom: 10px;
        }
        p {
          font-size: 14px;
          line-height: 28px;
        }
      }
      .btn {
        cursor: pointer;
        max-width: 80px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        div {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          color: #D92E1E;
          border: 2px solid #D92E1E;
          margin-bottom: 6px;
        }
      }
      .AppCode {
        width: 130px;
        height: 130px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        left: 50%;
        margin-left: -65px;
        margin-top: -50px;
        img {
          width: 100%;
          height: 100%;
          margin-top: 15px;
        }
      }
    }
  }
  .aboutUS {
    margin-top: 80px;
    padding: 70px 150px;
    background-color: #F9F9F9;
    z-index: -2;
    position: relative;
    .logo {
      width: 450px;
      position: absolute;
      top: -44px;
      left: -10px;
    }
    .background {
      width: 38%;
      height: 110%;
      position: absolute;
      right: 0;
      top: 0;
      z-index: -1;
    }
    .content {
      font-family: "Microsoft YaHei Regular";
      .title {
        h1 {
          color: #D92E1E;
          margin-bottom: 12px;
          font-size: 20px;
          font-weight: normal;
          width: 100%;
          position: relative;
          &::after {
            display: block;
            content: "";
            width: 22px;
            height: 2px;
            background-color: #D92E1E;
            position: absolute;
            top: 28px;
          }
        }
        span {
          color: #989898;
          margin-bottom: 36px;
        }
      }
      .imgList {
        display: flex;
        margin-top: 22px;
        margin-bottom: 22px;
        width: 80%;
        justify-content: space-between;
        li {
          width: 18%;
          img {
            width: 100%;
          }
        }
      }
      .text {
        h1 {
          color: #D92E1E;
          font-size: 26px;
          margin-bottom: 14px;
        }
        p {
          width: 39%;
          display: flex;
          flex-wrap: wrap;
          color: #656565;
        }
      }
    }
  }
  .partner {
    display: flex;
    justify-content: space-between;
    font-family: "Microsoft YaHei Regular";
    margin-top: 140px;
    margin-bottom: 50px;
    align-items: flex-start;
    .left {
      width: 40%;
      position: relative;
      background: url("../../../public/homeImg/index-4-pic01@2x.png") no-repeat;
      background-size: 100% 100%;
      padding-bottom: 200px;
      padding-top: 130px;
      padding-right: 50px;
      color: white;
      & > img {
        width: 200px;
        position: absolute;
        right: -24px;
        top: 54px;
      }
      p {
        margin-right: 50px;
        margin-top: 150px;
        margin-left: 150px;
        margin-bottom: 60px;
      }
      h1 {
        margin-right: 50px;
        margin-left: 150px;
        font-size: 20px;
        margin-bottom: 18px;
      }
      h2 {
        margin-right: 50px;
        margin-left: 150px;
        font-size: 18px;
        text-overflow: -o-ellipsis-lastline;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
    .middle {
      width: 40%;
      margin-top: 50px;
      .title {
        h1 {
          color: #D92E1E;
          margin-bottom: 12px;
          font-size: 20px;
          font-weight: normal;
          width: 100%;
          position: relative;
          &::after {
            display: block;
            content: "";
            width: 40px;
            height: 2px;
            background-color: #D92E1E;
            position: absolute;
            top: 30px;
          }
        }
        span {
          color: #989898;
          margin-bottom: 36px;
        }
      }
      .logos {
        position: relative;
        img:nth-child(1) {
          width: 185px;
          height: 185px;
          border-radius: 50%;
          position: absolute;
          top: 70px;
          left: 10px;
        }
        img:nth-child(2) {
          width: 140px;
          height: 140px;
          border-radius: 50%;
          position: absolute;
          top: 88px;
          left: 400px;
        }
        img:nth-child(3) {
          width: 160px;
          height: 160px;
          border-radius: 50%;
          position: absolute;
          top: 360px;
          left: -36px;
        }
        img:nth-child(4) {
          width: 96px;
          height: 96px;
          border-radius: 50%;
          position: absolute;
          top: 240px;
          left: 270px;
        }
        img:nth-child(5) {
          width: 120px;
          height: 120px;
          border-radius: 50%;
          position: absolute;
          top: 480px;
          left: 200px;
        }
        img:nth-child(6) {
          width: 130px;
          height: 130px;
          border-radius: 50%;
          position: absolute;
          top: 410px;
          left: 460px;
        }
      }
    }
    .right {
      img {
        width: 50%;
        height: 530px;
      }
    }
  }
  .contactUS {
    background-color: #F9F9F9;
    display: flex;
    padding: 25px 150px;
    justify-content: space-between;
    align-items: center;
    .left {
      width: 48%;
      img {
        width: 100%;
        height: 320px;
      }
    }
    .right {
      width: 48%;
      margin-top: 45px;
      .title {
        margin-bottom: 60px;
        h1 {
          color: #D92E1E;
          margin-bottom: 12px;
          font-size: 20px;
          font-weight: normal;
          width: 100%;
          position: relative;
          &::after {
            display: block;
            content: "";
            width: 40px;
            height: 2px;
            background-color: #D92E1E;
            position: absolute;
            top: 30px;
          }
        }
        span {
          color: #989898;
          margin-bottom: 36px;
        }
      }
      div {
        margin-bottom: 20px;
        display: flex;
        span:nth-child(1) {
          width: 10%;
        }
        span:nth-child(2) {
          width: 76%;
        }
      }
    } 
  }
}
</style>