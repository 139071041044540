var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{ref:"scrollBox"},[_c('Header',{attrs:{"navList":_vm.navList,"activeNav":_vm.activeNav},on:{"handleClick":_vm.changeActive}}),_c('div',{staticClass:"container"},[_c('div',{staticClass:"carimg",class:{close:_vm.myTrue}}),_c('section',{staticClass:"skip_news",class:{close:_vm.myTrue}},[_c('section',{staticClass:"skip"},[_c('section',[_c('div',{staticClass:"info",staticStyle:{"background-color":"rgba(0, 0, 0, 0.6)"}},[_vm._m(0),_c('div',{staticClass:"btn",on:{"click":_vm.toPlatform}},[_vm._m(1),_c('el-link',{staticStyle:{"color":"#D92E1E"},attrs:{"type":"primary"}},[_vm._v("点击进入")])],1)])]),_c('section',[_c('div',{staticClass:"info"},[_vm._m(2),_c('div',{staticClass:"btn",on:{"click":_vm.toEnterprise}},[_vm._m(3),_c('el-link',{staticStyle:{"color":"#D92E1E"},attrs:{"type":"primary"}},[_vm._v("点击进入")])],1)])]),_vm._m(4)])]),_c('div',{class:{close:_vm.isTrue}},[_vm._m(5),_vm._m(6),_vm._m(7)]),_c('Footer')],1)],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"86%"}},[_c('h1',[_vm._v("运营平台")]),_c('p',[_vm._v("平台对所有托运人、承运人、司机、运单、发票等进行管理")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"el-icon-right",staticStyle:{"font-size":"30px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"width":"62%"}},[_c('h1',[_vm._v("企业端")]),_c('p',[_vm._v(" 生产企业、贸易企业、物流公司等托运人发布货源、创建运单，同时对运单进行管理、运费结算，支持子账号模式 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',[_c('i',{staticClass:"el-icon-right",staticStyle:{"font-size":"30px"}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"info",staticStyle:{"background-color":"rgba(0, 0, 0, 0.6)","padding-bottom":"60px"}},[_c('div',[_c('h1',[_vm._v("司机端")]),_c('p',[_vm._v("通过APP接单、发货、在途信息管理、定位数据上传、收款管理")])])]),_c('div',{staticClass:"AppCode"},[_c('span',[_vm._v("扫码下载")]),_c('img',{attrs:{"src":"http://whptcs.dzwliu.com/static/ewm.png","alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"aboutUS"},[_c('img',{staticClass:"logo",attrs:{"src":require("../../../public/homeImg/_e-ABOUTUS.png")}}),_c('section',{staticClass:"content"},[_c('section',{staticClass:"title"},[_c('h1',[_vm._v("关于我们")]),_c('span',[_vm._v("公司简介，相关资讯")])]),_c('ul',{staticClass:"imgList"},[_c('li',[_c('img',{attrs:{"src":require("../../../public/homeImg/index-3-pic02@2x.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../../../public/homeImg/index-3-pic03@2x.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../../../public/homeImg/index-3-pic04@2x.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../../../public/homeImg/index-3-pic05@2x.png"),"alt":""}})]),_c('li',[_c('img',{attrs:{"src":require("../../../public/homeImg/index-3-pic06@2x.png"),"alt":""}})])]),_c('section',{staticClass:"text"},[_c('h1',[_vm._v("四川省交通运输集团有限责任公司")]),_c('p',[_vm._v(" 四川省交通运输集团是四川省委省政府着力解决我省交通运输市场小、散、弱主体难题，促进我省物流业专业化、现代化国际化水平的重要举措之一。 ")]),_c('p',{staticStyle:{"margin-top":"3px"}},[_vm._v(" 四川省交通运输集团以“交通四方，运达全球”为宗旨，立足四川、链接世界，现已成为全资质、全产业链的综合物流服务商—四川省交通运输行业龙头企业。 ")])])]),_c('img',{staticClass:"background",attrs:{"src":require("../../../public/homeImg/index-3-pic01@2x.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"partner"},[_c('section',{staticClass:"left"},[_c('p',[_vm._v(" 根据实际情况，本次合作，甲乙双方合作方式为办事处和经销商的结合体——经销商式的办事处。这样，既能利用办事处的垂直性进行有效统筹管理，也可发挥经销商多点出击的战斗力。 ")]),_c('h1',[_vm._v("合作机构：")]),_c('h2',[_vm._v(" 金龙客车、招商银行、富士康科技集团、腾讯、 宇通客车、西南交通大学Lorem ipsum dolor sit amet consectetur adipisicing elit. Praesentium asperiores unde beatae quas quia, sit incidunt nisi dignissimos amet quo minima ducimus maxime, eos vero obcaecati corporis? Quibusdam, consectetur fugiat! ")]),_c('img',{attrs:{"src":require("../../../public/homeImg/index-4-pic02@2x.png"),"alt":""}})]),_c('section',{staticClass:"middle"},[_c('section',{staticClass:"title"},[_c('h1',[_vm._v("合作伙伴")]),_c('span',[_vm._v("战略合作，多赢发展")])]),_c('section',{staticClass:"logos"},[_c('img',{attrs:{"src":require("../../../public/homeImg/index-comp-pic1.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../../public/homeImg/index-comp-pic2.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../../public/homeImg/index-comp-pic3.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../../public/homeImg/index-comp-pic4.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../../public/homeImg/index-comp-pic5.png"),"alt":""}}),_c('img',{attrs:{"src":require("../../../public/homeImg/index-comp-pic6.png"),"alt":""}})])]),_c('section',{staticClass:"right"},[_c('img',{attrs:{"src":require("../../../public/homeImg/index-4-pic01_1@2x.png"),"alt":""}})])])
},function (){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"contactUS"},[_c('section',{staticClass:"left"},[_c('img',{attrs:{"src":require("../../../public/homeImg/index-5-pic01@2x.png"),"alt":""}})]),_c('section',{staticClass:"right"},[_c('section',{staticClass:"title"},[_c('h1',[_vm._v("联系我们")]),_c('span',[_vm._v("意见反馈，详情咨询")])]),_c('div',[_vm._v("电话：180 8150 0858")]),_c('div',[_c('div',[_vm._v("地址：四川省达州市高新区木河路4号达州公路物流港综合办公楼2号楼3楼315、317号")])])])])
}]

export { render, staticRenderFns }